body {
  padding-top: 65px;
  /*padding-bottom: 30px;*/
  background-color: #fff;
}

.bold {
    font-weight: bold;
}

.btn-signin {
    width: 50%;
    margin-top: 5%;
}

.center {
    text-align: center;
}

.footer-master {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    background-color: #efefef;
    text-align: center;
    height: 6%;
}

.h1-title {
    color: #5b94c5;
    font-weight: bold;
    margin-bottom: 5%;
    margin-top: 5%;
}

.image-main-logo {
    height: 100%;
    width: auto;
}

.margin-bottom-5 {
    display: block;
    margin-bottom: 5%;
}

.margin-bottom-10 {
    display: block;
    margin-bottom: 10%;
}

.margin-top-5 {
    display: block;
    margin-top: 5%;
}

.margin-top-10 {
    display: block;
    margin-top: 10%;
}

.message-bold {
    font-weight: bold;
}

.navbar-brand {
    img {
        height: auto;
        width: 90%;
        position: relative;
        bottom: 50%;
    }
}

.news-marquee {
    height: 500px;
}
.news-title {
    margin-top: 25px;
    font-size: 20px;
    font-weight: bold;
}
.news-text {
    font-size: 14px;
    margin-bottom: 25px;
}

.ordered-list li {
    margin: 1% 0;
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.row > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.table-text-btn-align {
    display: block;
    margin-top: 5px;
}

.table-title {
    font-weight: bold;
    font-size: 20px;
    color: #5b94c5;
}

.text-instructions {
    font-size: 16px;
    /* testing */
}

.theme-dropdown .dropdown-menu {
  position: static;
  display: block;
  margin-bottom: 20px;
}

.theme-showcase > p > .btn {
  margin: 5px 0;
}

.theme-showcase .navbar .container {
  width: auto;
}

.well-signin {
    background: #c2dcf7;
    min-height: 396px;
}

.well-center {
    text-align: center;
    background: rgb(255,250,240);
}

.well-container-center {
    width: 80%;
    margin: 0 auto;
}

// PARSLEY
input.parsley-success,
select.parsley-success,
textarea.parsley-success {
  color: #468847;
  background-color: #DFF0D8;
  border: 1px solid #D6E9C6;
}

input.parsley-error,
select.parsley-error,
textarea.parsley-error {
  color: #B94A48;
  background-color: #F2DEDE;
  border: 1px solid #EED3D7;
}

.parsley-errors-list {
  margin: 2px 0 3px;
  padding: 0;
  list-style-type: none;
  font-size: 0.9em;
  line-height: 0.9em;
  opacity: 0;

  transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
}

.parsley-errors-list.filled {
  opacity: 1;
}

.list-instructions {
    font-weight: bold;
    font-size: 16px;
}
